
    import {Component, Emit, Inject, Model, Prop, Provide, Vue, Watch} from 'vue-property-decorator';

    @Component({
        components: {},
    })
    export default class Loading extends Vue {
        @Prop({
            type: Boolean,
            required: false,
            default: () => (false),
        })
        private show: boolean = false;

        @Prop({
            type: String,
            required: false,
            default: () => (''),
        })
        private icon!: string;

        @Prop({
            type: String,
            required: false,
            default: () => (''),
        })
        private tips!: string;

        public isShow() {
            return this.show;
        }

        public open() {
            this.show = true;
        }

        public close() {
            this.show = false;
        }
    }

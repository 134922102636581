
import { Component, Emit, Inject, Model, Prop, Provide, Vue, Watch } from 'vue-property-decorator';
import Loading from '@/views/component/loading/Loading.vue';
import ContactAddApply from '@/views/module/contact/apply/ContactAddApply.vue';
import app from '@/app/App';
import loginViewModel from '@/platform/vue/view/model/LoginViewModel';

import AppSettingManager from '@/app/com/client/module/setting/manager/AppSettingManager';
import ServerService from '@/app/com/main/module/business/server/service/ServerService';

import Prompt from '@/platform/wap/common/Prompt';

// 添加好友
import DataBackAction from '@/app/base/net/DataBackAction';
import ContactAddApplyData from '@/app/com/main/module/business/contact/data/ContactAddApplyData';
import ContactController from '@/app/com/main/module/business/contact/controller/ContactController';
import PersonalBox from '@/app/com/main/module/business/personal/box/PersonalBox';
// 发送消息
import UserChatViewController from '@/app/com/main/module/business/chat/controller/UserChatViewController';

@Component({
    components: {
        Loading,
        ContactAddApply
    },
})
export default class Login extends Vue {
    private model = loginViewModel;
    private rules = {
        accountRule: [
            (v: any) => !!v || '用户名不能为空',
            (v: string) => (/^[\S]{4,20}$/.test(v) || '4-20位'),
        ],
        passwordRule: [
            (v: any) => !!v || '密码不能为空',
            (v: string) => (/^[\S]{6,20}$/.test(v) || '6-20位'),
        ],
    };
    public onLogin: (success: boolean, message?: string) => void = (
        (success: boolean, message?: string) => {
            console.log('登录信息', success, message)
            if (message) {
                Prompt.error(message);
            }
            if (success) {
                this.toMain();
            }
        }
    );

    private settingDialog: boolean = false;
    private serverUrl: string = '';
    private formName = 'form';


    public mounted() {
        // no
        // loginViewModel.onLogin = this.onLogin;
        // loginViewModel.initialize();
        // loginViewModel.data.savePassword = true;
        // loginViewModel.data.autoLogin =  true;
        // loginViewModel.hasLogin = true;

        let obj1: any = JSON.parse(localStorage.getItem('uniData') || '{}');
        console.log('登录数据', obj1);
        loginViewModel.hasLogin = false;
        loginViewModel.data.account = obj1.body.user.account;
        loginViewModel.data.password = '';
        loginViewModel.data.signal = obj1.body.user.signal;
        const back = this.onLogin;
        loginViewModel.login(() => {
            return true;
        }, back);
    }

    public login(): void {
        const own = this;
        const form: any = this.$refs[this.formName];
        const valid = form.validate();
        if (valid) {
            const back = this.onLogin;
            loginViewModel.login(
                () => {
                    return true;
                },
                back);
        }
    }

    public resetPassword(): void {
        Prompt.error('还没实现😁');
    }

    public setting(): void {
        const asm: AppSettingManager = app.appContext.getMaterial(AppSettingManager);
        this.serverUrl = asm.getServerUrl();
        this.settingDialog = true;
    }

    public saveSetting(): void {
        const own = this;
        const url = this.serverUrl;
        if ('' !== url && null !== url) {
            const asm: AppSettingManager = app.appContext.getMaterial(AppSettingManager);
            asm.setServerUrl(url);

            const addressBack = (success: boolean, message?: string) => {
                if (!success) {
                    Prompt.error('获取服务器地址失败！请检查网络是否正常');
                }
                own.settingDialog = false;
            };

            const serverService: ServerService = app.appContext.getMaterial(ServerService);
            serverService.loadServerAddress(addressBack);
        }
    }

    public register(): void {
        this.$router.push({ path: '/register' });
    }

    private toMain(): void {
        // history.replaceState()
        // location.replace('/#/main')
        console.log('去首页')
        this.sendAddRequest();
        // this.$router.replace('/main');

        // this.$router.push({path: '/main'});
    }

    private apply: ContactAddApplyData = new ContactAddApplyData();
    private sendAddRequest() {
        const own = this;
        const back: DataBackAction = {
            back(data: any): void {
                if (data) {
                    const info = data.info;
                    if (info) {
                        // if (info.success) {

                        // } else {
                        //     Prompt.message(info, '', '');
                        // }
                        console.log('加好友', info)
                        // 发送消息
                        const controller: UserChatViewController = app.appContext.getMaterial(UserChatViewController);
                        controller.showUserChatById(localStorage.getItem('friendId') || '');
                    }
                }
            },
            lost(data: any): void {
                console.log('请求失败！')
                Prompt.notice('请求失败！');
            },
            timeOut(data: any): void {
                console.log('请求超时！')
                Prompt.notice('请求超时！');
            },
        } as DataBackAction;

        const apply: ContactAddApplyData = this.apply;
        const contactController: ContactController = app.appContext.getMaterial(ContactController);
        const personalBox: PersonalBox = app.appContext.getMaterial(PersonalBox);
        apply.targetUserId = localStorage.getItem('friendId') || '';
        apply.applyUserId = personalBox.getUserId();
        contactController.sendAddRequest(apply, [], back);
    }

    get getHeight(): number {
        const height = document.documentElement.clientHeight;
        return height;
    }
}
